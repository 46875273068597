
const deployed = true
 
 

const prod = {
  endpoint: "https://us-central1-collectwise-dev.cloudfunctions.net",
  GETTRX_URL: "https://onboard.iso.gettrx.com",
  EDGEPAY_SCRIPT:
    "https://static.edgepayportal.com/public/js/edgepayPivot.min.js",
  FLAT_RATE_OFFERID: "36df465e-e582-45e6-a22b-5828560bc569",
  INTERCHANGE_PLUS_OFFERID: "79bd4d5d-caec-46b2-9cd1-61e2f97abe1a",
  DROMO_LICENSE_KEY: "629aa5cc-d71d-4fe0-9e86-16f2636e9c80",
  DROMO_DEVELOPMENT_MODE: false,
  version: "production",
}


const dev = {
  endpoint: "http://localhost:5001/collectwise-dev/us-central1",
  GETTRX_URL: "https://onboard.iso-dev.gettrx.com",
  EDGEPAY_SCRIPT:
    "https://static.portal.edgepay-uat.com/public/js/edgepayPivot.min.js",
  FLAT_RATE_OFFERID: "b71c5f3d-a377-4fef-adec-d41e623d89cf",
  INTERCHANGE_PLUS_OFFERID: "894eb6c5-706c-4299-bc49-19f3501b9092",
  DROMO_LICENSE_KEY: "629aa5cc-d71d-4fe0-9e86-16f2636e9c80",
  DROMO_DEVELOPMENT_MODE: true,
  version: "sandbox",
}

export const config = deployed ? prod : dev;
