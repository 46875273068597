// Link in email can be changed here: https://console.firebase.google.com/u/3/project/astrius-dev/authentication/emails
import { applyActionCode, verifyPasswordResetCode } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {isMobile} from 'react-device-detect';
import { auth } from '../firebase';
import { PlasmicGetStarted2 } from "./plasmic/collect_wise_draft/PlasmicGetStarted2";
import { v4 } from "uuid";
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, startAt, endAt, startAfter, endBefore, limitToLast } from "firebase/firestore";
import { db } from "../firebase"
import LoadingSpinner from './LoadingSpinner';
import CreateNewPassword from "./CreateNewPassword";


const VerifyEmail = () => {
  const { currentUser, setIsVerified } = useAuth();
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ?mode=action&oobCode=code
  const { search } = useLocation();
  const [actionCode, setActionCode] = useState(null);
  const [resetPassword, setResetPassword] = useState(false);
  const history = useHistory();

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    const mode = searchParams.get('mode');
    const actionCode = searchParams.get('oobCode');
    setActionCode(actionCode)
 
    if (actionCode == null) {
      setHasError(true);
      setLoading(false);
      return;
    } else if (mode === 'resetPassword') {
      
      verifyPasswordResetCode(auth, actionCode).then( async () => {
        
        // now set the state to the plasmic wrapper page to enter the new password and call confirmPasswordReset as described here https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#confirmpasswordreset
        setResetPassword(true);
        setLoading(false);

      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setHasError(true);
      })

    } else if (mode === 'verifyEmail' ) {

    
    applyActionCode(auth, actionCode)
      .then(async () => {
        if (currentUser != null) {
          
          window.addEventListener("beforeunload", handleBeforeUnload);

          var docId = v4()
    
          await setDoc(doc(db, "userInfo", docId), {
            user: currentUser.uid,
            email: currentUser.email,
            identityVerification: false,
            autoEnrollDebtors: false,
            invoiceNum: 1,
            date: new Date(),
            customPrice: 0,
            customLimit: 0,
            monthlyAmount: 0,
            cardApproval: false,
            heartlandPublicKey: "",
            heartlandSecretKey: "",
            fraudCount: 0,
            merchantName: "",
            collectionsEmail: "",
            requiresRNDCheck: false,
            isAgency: false,
            customWorkflowID: ""
          })
        
          window.removeEventListener("beforeunload", handleBeforeUnload);
    

          currentUser.reload().then(() => history.push('/get-started'));

        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setHasError(true);
      });

    } else {
      setHasError(true);
      setLoading(false);
      return;
    }

  }, []);



  if (loading) {
    return <LoadingSpinner/>;
  }

  if (hasError) {
    return <div>Invalid Verification Link</div>;
  } else if (resetPassword) {
    return <CreateNewPassword code={actionCode}/>;
  } else {

    if (isMobile){
      return <PlasmicGetStarted2/>
    } else {

      return (
        <div>
          Your email has been verified. Please return to the site and login again to setup your
          profile.
        </div>
      );

    }

  }
};

export default VerifyEmail;
