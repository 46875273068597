// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicClientsListCard } from "./plasmic/collect_wise_draft/PlasmicClientsListCard";
import { useHistory } from 'react-router-dom';

function ClientsListCard_(props, ref) {
  const history = useHistory()

  

  return <PlasmicClientsListCard root={{ ref,
  onClick: () => {
    if (props.clientEmail === "sean@collectwise.co" || props.clientEmail === "vivek@collectwise.co"){
      window.location.href = `https://admin.collectwise.com/?id=${props.debtorId}`
    } else {
      history.push(`debtor/${props.id}`)
    }
  }
  }} {...props} />;
}


const ClientsListCard = React.forwardRef(ClientsListCard_);

export default ClientsListCard;
