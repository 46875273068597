// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, {useState} from "react";
import { PlasmicDebtorContact } from "./plasmic/collect_wise_draft/PlasmicDebtorContact";
import { useAuth } from "../contexts/AuthContext";

function DebtorContact_(props, ref) {

  // const [contactNameError, setContactNameError] = useState('')
  const { currentUser, contactFields, setContactFields } = useAuth()
  const [contactName, setContactName] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [contactEmail, setContactEmail] = useState('')




function updateContactName() {
  
  var temp = contactFields
  
  for (var i = 0; i < temp.length; i++) {
    
  if (temp[i].id === props.id){
    temp[i].name = contactName
    
    break;
  }
  
  }
  
  setContactFields(temp)
 
  
  }


  function updateContactPhone() {

    var temp = contactFields

    for (var i = 0; i < temp.length; i++){
      

    if (temp[i].id === props.id){
      temp[i].phone = contactPhone
      break;
    }
    
    }
    
    setContactFields(temp)

    
    }
  

    function updateContactEmail() {
  
      var temp = contactFields
      
      for (var i = 0; i < temp.length; i++) {
        
      if (temp[i].id === props.id){
        temp[i].email = contactEmail
        break;
      }
      
      }
      
      setContactFields(temp)
      
      
      }

  


  return <PlasmicDebtorContact root={{ ref }} {...props}
  contactNameText={props.number === "1" ? "Contact Name" : `Contact ${props.number} Name`}
  contactPhoneText={props.number === "1" ? "Contact Phone Number" : `Contact ${props.number} Phone Number`}
  contactEmailText={props.number === "1" ? "Contact Email" : `Contact ${props.number} Email`}
  contactName={{
    value: contactName,
    onChange: (e) => {
      setContactName(e.target.value)
    },
    onBlur: (e) => {
      updateContactName()
    }
  }}
  contactPhone={{
    value: contactPhone,
    onChange: (e) => {
      setContactPhone(e.target.value)
    },
    onBlur: (e) => {
      updateContactPhone()
    }
  }}
  contactEmail={{
    value: contactEmail,
    onChange: (e) => {
      setContactEmail(e.target.value)
    },
    onBlur: (e) => {
      updateContactEmail()
    }
  }}
  contactNameError={{
    wrap: (node) => (props.contactNameError && contactName === "") ? node : null,
  }}
  contactPhoneError={{
    wrap: (node) => (props.contactPhoneEmailError && (contactEmail === '' && contactPhone === '') ) ? node : null,
  }}
  contactEmailError={{
    wrap: (node) => (props.contactPhoneEmailError && (contactEmail === '' && contactPhone === '')) ? node : null,
  }}
  deleteField={{
    wrap: node => props.number === "1" ? null : node,
    props: {
      onClick: () => {
        setContactFields(contactFields.filter((x) => x.id !== props.id))
      }
    }
  }}
  />;
}

const DebtorContact = React.forwardRef(DebtorContact_);

export default DebtorContact;
